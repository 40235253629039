import { searchPrefix } from '@hubcms/brand';

export function createStructuredDataWebSite(origin: string) {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: origin,
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `${origin}/${searchPrefix}?q={search_term_string}`,
      },
      'query-input': 'required name=search_term_string',
    },
  };
}
